import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, UrgentCare, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { UrgentCareImagesQuery } from '../shared/interfaces';

const UrgentCarePage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<UrgentCareImagesQuery>(graphql`
        query {
            urgentCareBackgroundImage: file(name: {eq: "urgentCareBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            urgentCareSummaryImage: file(name: {eq: "urgentCareSummary"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Urgent Care"/>
            <GeneralJumbotron
                title="Urgent Care"
                backgroundImageURL={queryResult?.urgentCareBackgroundImage.publicURL}
                backgroundText="Urgent Care"
            />
            <SummaryTemplate image={queryResult?.urgentCareSummaryImage.publicURL}>
                <h2>{`Urgent Care Providers`}</h2>
                <p>
                    {`
                        Our urgent care providers include doctors, nurse practitioners, and
                        physician assistants. They are specially trained to handle urgent
                        situations without the wait time of an emergency room. It’s a great
                        solution when your regular doctor is not available—you don’t need an
                        appointment, and most locations have extended hours. PCH values the
                        importance of urgent care centers and the role they play when it comes
                        to being seen by a doctor—fast. With a statewide network, you can find
                        the doctor who’s closest to your work, home, or school.
                    `}
                </p>
            </SummaryTemplate>
            <CallToAction/>
        </MainLayout>
    );
}

export default UrgentCarePage;
